<template>
  <section
    :key="selected_bot.id"
    :class="{
      chats: true,
      'botton-line': searchedChat && searchedChat.length >= 10,
    }"
  >
    <div class="menu">
      <h3 class="mb-0">Chats</h3>
      <div class="row">
        <span
          :class="{
            'btn-select': true,
            'mx-1,': true,
            'btn-select-active': selected == 1,
          }"
          @click="selected = 1"
          ><span v-if="db_table && db_table.length != 0">{{
            db_table.length || ''
          }}</span>
          Activas</span
        >
        <span
          :class="{
            'btn-select': true,
            'mx-1,': true,
            'btn-select-active': selected == 2,
          }"
          @click="selected = 2"
        >
          <span v-if="activeChatRooms && activeChatRooms.length != 0">{{
            activeChatRooms.length || ''
          }}</span>
          Tomadas</span
        >
        <span
          :class="{
            'btn-select': true,
            'mx-1,': true,
            'btn-select-active': selected == 3,
          }"
          @click="selected = 3"
        >
          <span v-if="pendingChats && pendingChats.length != 0">{{
            pendingChats.length || ''
          }}</span>
          Pendientes</span
        >
      </div>
      <div class="search_menu">
        <input placeholder="Buscar" v-model="searchChat" type="search" />
        <span
            @click="showDropdownFilter = !showDropdownFilter"
            :class="{
              'i-btn':true,
              'i-filter': !showDropdownFilter,
              'i-btn-active i-close i-st': showDropdownFilter
            }"
          ></span>
          <MultiselectTag @sendCategoryFilter="tagsFilter = $event" :showDropdown="showDropdownFilter" :items="tags" />
        <!-- <span
            @click="showDropdownFilter = !showDropdownFilter"
            :class="{
              'i-btn':true,
              'i-filter': !showDropdownFilter,
              'i-btn-active i-close i-st': showDropdownFilter
            }"
          ></span>
          <Multiselect @sendCategoryFilter="categoryFilter = $event" :showDropdown="showDropdownFilter" :items="areas" /> -->
        <span
          @click="$emit('sendShowArchived', true)"
          class="i-btn i-archive-b"
        ></span>
      </div>
    </div>
    <spinnerLoading v-if="loading" />
    <template v-else>
      <FadeTransition :duracion="500" :key="selected">
        <template v-if="searchedChat && searchedChat.length > 0">
          <div class="scroll_box">
            <div
              v-for="chat in searchedChat"
              :key="chat.id"
              @click="generateChat(chat)"
              :class="{
                to_card: true,
                selected_card: selectedChat
                  ? selectedChat.id === chat.id
                  : false,
              }"
            >
              <template v-if="chat && chat.databot_live">
                <div class="card-box">
                  <span
                    v-if="chat.channel !== 'Whatsapp Bot' && chat.channel !== 'instagram' && chat.channel !== 'messenger'"
                    class="user-img"
                  >{{
                      (chat.databot_live.lead.name &&
                        chat.databot_live.lead.name[0]) ||
                      'C'
                    }}</span
                  >
                  <span
                    v-else
                    :class="{
                      'user-img-wsp':chat.channel === 'Whatsapp Bot',
                      'user-img-instagram':chat.channel === 'instagram',
                      'user-img-messenger':chat.channel === 'messenger'
                    }"></span>
                  <div>
                    <div class="row">
                      <div class="col-8 px-0">
                        <label v-if="chat.databot_live.lead" class="mb-0 mr-2">
                          {{
                            !chat.databot_live.lead.name ||
                            chat.databot_live.lead.name == '--'
                              ? `Cliente`
                              : chat.databot_live.lead.name
                          }}
                        </label>
                        <span
                          v-if="chat.category && chat.category != 'general'"
                          class="etiqueta label-p-sm mr-1"
                          >{{ chat.category }}</span
                        >
                      </div>
                      <div class="col-4 pl-2 pr-0">
                        <p class="text_sm text-right m-0">
                          {{
                            chat.last_message && chat.last_message.updated_at
                              ? $moment(chat.last_message.updated_at)
                                  .locale('es')
                                  .fromNow() == 'hace unos segundos'
                                ? 'ahora'
                                : $moment(chat.last_message.updated_at)
                                    .locale('es')
                                    .fromNow()
                                    .includes('hace')
                                ? $moment(chat.last_message.updated_at)
                                    .locale('es')
                                    .fromNow()
                                    .replace('hace', '')
                                : $moment(chat.last_message.updated_at)
                                    .locale('es')
                                    .fromNow()
                              : $moment(chat.updated_at).locale('es').fromNow()
                          }}.
                        </p>

                        <!-- <i
                          v-if="chat.channel === 'Whatsapp Bot' && selected === 1"
                          @click.stop="deletePrechat(chat)"
                          class="fas fa-times"
                        /> -->
                      </div>
                      <!-- <p
                        v-if="chat.client_disconected"
                        class="text_sm my-1"
                      >
                        Desconectado
                      </p> -->
                    </div>
                    <div class="label-box">
                      <p class="user-message mt-2 mb-0">
                        {{
                          chat.last_received_message ||
                          'Sin mensaje inicial'
                        }}
                      </p>
                      <span
                        v-if="(chat.channel === 'Whatsapp Bot' || chat.channel === 'instagram' || chat.channel === 'messenger') && selected === 1"
                        class="i-btn i-btn-sm i-archive mb-0 mr-0"
                        @click.stop="hidePrechat(chat)"
                      ></span>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div v-if="allChats.length == 10" class="row my-4">
              <button
                v-show="showLoadChat"
                class="text-state text-state-md mx-auto px-3"
                @click="loadMoreChats"
              >
                Cargar más
              </button>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="info_box mx-3 my-4">
            <p class="text-center">Sin conversaciones</p>
          </div>
        </template>
      </FadeTransition>
    </template>
  </section>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import socket from '@/plugins/sockets';
import environment from '@/environment';
import { mapState, mapMutations } from 'vuex';
import Multiselect from '@/components/Multiselect2';
import MultiselectTag from '@/components/MultiselectTags';
import dashboard_api from '@/dashboard_api';
import dashboard_api_v2 from '@/dashboard_api_v2';
import filtersDatabotLive from '@/utils/filtersDatabotLive.js';
import { FadeTransition } from 'vue2-transitions/dist/vue2-transitions.cjs';
import DesarchivarConversacion from '@/components/DesarchivarConversacion.vue';

export default {
  props: [
    'setConnect',
    'agents',
    'modal',
    'toArchive',
    'selectedChat',
    'getUpdateChatDate',
    'agentAccount',
    'sendPushDataKey',
    'toUnArchivedChat',
    'tags'
  ],

  components: {
    Multiselect,
    MultiselectTag,
    FadeTransition,
    DesarchivarConversacion,
  },

  data() {
    return {
      searchChat: '',
      selected: 1,
      files: 0,
      showDropdownFilter: false,
      areas: [
        {
          name: 'Ventas',
        },
        {
          name: 'Facturación',
        },
        {
          name: 'Post-venta',
        },
        {
          name: 'Soporte',
        },
      ],
      hasCustomFilters: false,
      db_table: [],
      pendingChats: [],
      perPage: 10,
      color: '',
      setColors: [],
      selectedFilters: {},
      customFilters: {},
      archivatedAgent: {},
      activeChats: [],
      categoryFilter: [],
      tagsFilter: [],
      loading: false,
      // para contador
      countDown: 60,
      chatRooms: [],
      showLoadChat: true
    };
  },
  mounted() {
    // this.countDownTimer();
    socket.on('UPDATE_DATABOTLIVE_CARD', ({ payload, room }) => {
      console.log("UPDATE_DATABOTLIVE_CARD->", payload);

      if (!payload.category || payload.category == '') {
        payload.category = 'general';
      }
      // Agregando conversación pendiente a array para validaciones
      this.db_table.forEach((el, index, array) => {
        if (el.room_token == room) {
          // actualizando tarjeta en "Todas las conversas"
          el.databot_live.lead.name = payload.name;
          el.databot_live.lead.email = payload.email;
          el.databot_live.lead.phone = payload.phone;
          el.databot_live.lead.question = payload.question;
          el.databot_live.lead.custom = payload.custom;
          el.category = payload.category;
          // actualizando tarjeta de informacion del contacto
          if (this.roomToken === room) {
            this.modal.name = payload.name;
            this.modal.phone = payload.phone;
            this.modal.email = payload.email;
            this.modal.question = payload.question;
            this.modal.rut = payload.rut;
            this.modal.contact_time = payload.contact_time;
            // this.modal.custom = payload.custom;
            this.modal.category = payload.category;
            this.$emit('sendUpdateModal', this.modal);
          }
        }
      });
      this.activeChatRooms.forEach((el, index, array) => {
        if (el.room_token == room) {
          array[index].category = payload.category;
          array[index].client_state = payload.client_state;
          // actualizando tarjeta de informacion del contacto
          if (this.roomToken === room) {
            this.modal.category = payload.category;
            this.$emit('sendUpdateModal', this.modal);
          }
        }
      });

      this.pendingChats.forEach((pending, index, array) => {
        if (pending.room_token == room) {
          // actualizando tarjeta en "pendientes"
          pending.databot_live.lead = payload;
          // array.splice(index, 1, newLeadData);
        }
      });
      // Quitar conversación y las notificaciones al owner si el bot le asigna un área
      if (this.agentAccount.category && !this.agentAccount.category.includes(payload.category)) {
        this.setNewMessage(null);
        this.$swal({
          toast: true,
          html: `<span>La conversación cambió de área.</span>`,
          position: 'top-end',
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: false,
        });
        this.searchConversationCardAndDelete(room);
      }
    });

    socket.on('UPDATE_DATABOTLIVE_CARD_STATE', (data) => {
      console.log('UPDATE_DATABOTLIVE_CARD_STATE->', data);

      if (data.user && data.user.id != this.user.id) {
        this.setNewMessage(false);

        if (data.room == this.roomToken) {
          this.$swal({
            title: 'Otro agente tomó la conversación seleccionada.',
            icon: 'info',
            showConfirmButton: true,
          });
          this.setCurrentRoomToken(null);
          this.$emit('sendClearViewChat');
        } else {
          this.$swal({
            toast: true,
            icon: 'info',
            html: `<span> Un agente tomó una conversación.</span>`,
            position: 'top-end',
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: false,
          });
        }
        this.searchConversationCardAndDelete(data.room);
      }
    });
    // nueva conversacion en pendientes
    socket.on('NEW_PENDING_CONVERSATION', (data) => {
      this.db_table.forEach((chat_element, index, array) => {
        if (chat_element.room_token == data.room) {
          // moviendo tarjeta de todos a pendientes
          this.pendingChats.unshift(array[index]);
          // cambiando su estado a activo
          array[index].client_disconected = false;
          array[index].client_state = "pendiente";
          // eliminando tarjeta de todas las conversaciones
          array.splice(index, 1);
        }
      });
      this.$swal({
        icon: "info",
        toast: true,
        position: "top-right",
        text: "Solicitud de agente",
        timer: 4000,
        showConfirmButton: false
      });
    });
    // revisar
    socket.on('USER_DISCONECT_FROM_CHAT', (data) => {
      console.log('USER_DISCONECT_FROM_CHAT', data);

      let chat = this.allChats.find(el => el.room_token == data.room);
      console.log("chat->", chat);
      console.log("roomToken->", this.roomToken);

      if (chat) {
        chat.user_online = false;

        // Para no perder la conversación si está en pendientes y el user recarga la pag
        if (chat.client_state == "pendiente") return;

        if (data.room == this.roomToken) {
          // Si la conversación está seleccionada arroja mensaje
          this.$swal({
            title: 'El usuario cerró esta conversación.',
            icon: 'info',
            showConfirmButton: true,
          });
          this.$emit('sendClearViewChat');
          this.setCurrentRoomToken(null);
        } else {
          // Solo le vea la notificacion si corresponde al agente
          if (chat.agent_id == this.user.id) {
            // Si la conversación existe y no está seleccionada sale notificación
            this.$swal({
              toast: true,
              icon: 'info',
              html: `<span> Un usuario cerró su conversación.</span>`,
              position: 'top-end',
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: false,
            });
          }
        }
        this.setShowClientInfo(false);
        this.searchConversationCardAndDelete(data.room, 'disconnect');

        console.log('desconectado->');
      }
    }),
    // revisar
    socket.on('SET_CLIENT_STATE', (data) => {
      console.log("SET_CLIENT_STATE->", data);
      this.changeClientState(data.room, data.state);
    });
  },

  watch: {
    tagsFilter(value) {
      this.pushData();
      // this.$emit("onAddCategoryFilter", value.map(val => val.label_name));
    },

    getUpdateChatDate(val) {
      this.updatePrechatDateAndLastMessage(val.message, val.room);
    },
    // key para actualizar conversacion desarchivada
    async sendPushDataKey(val) {
      this.toUnArchivedChat.client_state == 'tomado'
        ? this.activeChats.unshift(this.toUnArchivedChat)
        : this.db_table.unshift(this.toUnArchivedChat);
    },
    async toArchive(val) {
      await this.hidePrechat();
    },
    setConnect(val) {
      if (val == true) this.connectAgent();
      if (val == false) this.disconnectAgent();
    },
    async push_data(val) {
      console.log("store_push_data", val);
      if (val.payload.agent_id && val.payload.agent_id != this.user.id) {
        this.searchConversationCardAndDelete(val.room_token);
        this.setNewMessage(false);
      }
      if (val.reconnect && val.payload.client_state == "pendiente") {
        this.setNewMessage(false);
      }
      if (!val.payload.agent_id || val.payload.agent_id == this.user.id) {
        await this.addDatabotLiveCard(val.payload);
      }
    },
    selected_bot: {
      immediate: true,
      async handler(val) {
        this.checkIfBotHasCustomFilters();
        this.pushData('');
        this.setSelectedChat(null);
        this.setNewMessage(false);
        this.setShowClientInfo(false);
        this.selected = 1;
      },
    },
    active_view(val) {
      this.pushData();
    },
  },
  computed: {
    ...mapState([
      'user',
      'ownerBots',
      'selected_bot',
      'activeChatRooms',
      'active_view',
      'roomToken',
      'new_message',
      'push_data'
    ]),
    // Total de conversaciones sin filtrar por canal
    allChats() {
      return this.db_table.concat(this.activeChats, this.pendingChats);
    },
    // filterCategoryChats() {
    //   let items = this.formattedChats;

    //   if (this.categoryFilter.length == 0) return items;

    //   return items.filter(el => {return this.categoryFilter.includes(el.category)});
    // },

    searchedChat() {
      let items = this.formattedChats;

      if (!this.searchChat) return items;

      return items.filter((el) => {
        let name = el.databot_live.lead.name
          ? el.databot_live.lead.name
          : 'Cliente';
        let question = el.databot_live.lead.question
          ? el.databot_live.lead.question
          : 'Sin mensaje inicial';

        return [name, question, el.category].find((field) => {
          return field
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(
              this.searchChat
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
                .trim(),
            );
        });
      });
    },
    formattedChats() {
      return this.chatsToShow.reduce((acc, chat) => {
        if (!acc.some(item => item.room_token === chat.room_token)) {
          acc.push(chat);
        }
        return acc;
      }, [])
        .sort((a, b) => {
          return (
            new Date(
              b.last_message ? b.last_message.updated_at : b.updated_at,
            ) -
            new Date(a.last_message ? a.last_message.updated_at : a.updated_at)
          );
        })
        .slice(0, 20);
    },
    chatsToShow() {
      switch (this.selected) {
        case 1:
          return this.db_table;
        case 2:
          return this.activeChatRooms;
        case 3:
          return this.pendingChats;
        default:
          return [];
      }
    },
    filteredChats() {
      return this.filterChats(this.chatsToShow);
    },
  },

  methods: {
    ...mapMutations([
      'setActiveChatRooms',
      'addActiveChatTokens',
      'addActiveChatRoom',
      'setShowClientInfo',
      'setSelectedChat',
      'setCurrentRoomToken',
      'setNewMessage',
    ]),

    loadMoreChats() {
      this.pushData('', true);

      if (this.allChats.length == 20) {
        this.showLoadChat = true;
      } else {
        this.$swal({
          toast: true,
          position: 'top-right',
          icon: 'success',
          text: ' No hay más conversaciones',
          timer: 4000,
          showConfirmButton: false
        });
        this.showLoadChat = false;
      }
    },
    // para contador
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    // async colorGenerator() {
    //   this.activeChatRooms.forEach(el => {
    //     el.color = '#' + (Math.random()*0xFFFFFF<<0).toString(16);
    //     console.log("here-->", el.color);
    //   })
    // },
    async archivedAgent() {
      this.archivatedAgent = (
        await dashboard_api.post('/chat_rooms/save_agent_history', {
          id: this.roomToken,
        })
      ).data;
      this.setCurrentRoomToken(null);
    },

    changeClientState(room, event) {
      this.db_table.forEach((chat_element, index, array) => {
        if (chat_element.room_token == room) {
          if (event === 'disconnect') {
            chat_element.client_disconected = true;
            chat_element.user_online = false;
          }
          if (event == 'connect') {
            chat_element.client_disconected = false;
            chat_element.user_online = true;
          }
        }
      });
      this.activeChats.forEach((active, index, array) => {
        if (active.room_token == room) {
          if (event === 'disconnect') {
            active.client_disconected = true;
            active.user_online = false;
          }
          if (event == 'connect') {
            active.client_disconected = false;
            active.user_online = true;
          }
        }
      });
      this.pendingChats.forEach((pending, index, array) => {
        if (pending.room_token == room) {
          if (event === 'disconnect') pending.user_online = false;
          if (event == 'connect') pending.user_online = true;
        }
      });
    },
    disconnectAgent() {
      // actualizando token si el usuario no dio click a la tarjeta

      this.$swal({
        title: '¿Terminar conversación?',
        icon: 'info',
        showConfirmButton: true,
        confirmButtonText: 'Terminar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          this.searchConversationCardAndDelete(this.roomToken);

          if (
            this.selectedChat &&
            this.selectedChat.channel &&
            (this.selectedChat.channel === 'Whatsapp Bot' || this.selectedChat.channel === 'instagram' || this.selectedChat.channel === 'messenger')
          ) {
            // otras plataformas de mensajeria siguen este camino
            // devolviendo a vista todas conversaciones
            this.db_table.unshift(this.selectedChat);
            dashboard_api
              .put(`/chat_rooms/${this.roomToken}`, {
                chat_room: {
                  client_state: null,
                  status: true,
                },
              })
              .catch((error) => console.log(error));
            // recargando prechat
            this.selectedChat.client_state = null;
            this.selectedChat.status = true;
            this.selectedChat.agent_id = null;
            this.db_table = this.db_table.slice();
            this.archivedAgent();
          } else {
            // camino de siempre
            dashboard_api
              .put(`/chat_rooms/${this.roomToken}`, {
                chat_room: {
                  client_state: 'terminado',
                  status: false,
                },
              })
              .catch((error) => console.log(error));
            this.archivedAgent();
          }
          // revisar
          socket.emit('STATUS', {
            room: this.roomToken,
            status: false,
            botId: this.selected_bot.id,
            channel: this.modal.channel,
            sender_id: this.modal.sender_id || null,
            options: {
              botPhone: this.modal.custom
                ? this.modal.custom.special_bot_phone
                : null,
              userPhone: this.modal.phone,
            },
          });
          // limpiando vista actual de conversacion
          this.$emit('sendClearViewChat');
          // set selectedChat null
          this.setSelectedChat(null);
          // Cierra columna de info
          this.setShowClientInfo(false);
          this.$swal({
            toast: true,
            icon: 'success',
            text: 'Terminada',
            position: 'top-end',
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: false,
          });
        }
      });
    },
    connectAgent() {
      const self = this;
      let checkActiveChat = null;
      self
        .$swal({
          title: 'Estás por ingresar a esta conversación',
          text: 'El chatbot quedará inactivo para este cliente, por favor pide los datos que falten.',
          icon: 'warning',
          showConfirmButton: true,
          confirmButtonText: 'Iniciar',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
        })
        .then((result) => {
          if (result.isConfirmed) {
            self.loading = true;
            // actualizando room token si se dio click al boton prechat hablar
            // if (self.selectedChat) self.roomToken = self.selectedChat.room_token;

            // VALIDACION: verificando si tarjeta aún existe
            if (
              !self.db_table.find(
                (chat) => chat.room_token === self.roomToken,
              ) &&
              !self.pendingChats.find(
                (chat) => chat.room_token === self.roomToken,
              )
            ) {
              self.$emit('sendClearViewChat');
              checkActiveChat = false;

              return self.swalAlert(
                'Ooops!',
                'El cliente al que intentabas contactar se desconectó',
                null,
                { icon: 'error', showCancelButton: false },
              );
            }
            checkActiveChat = true;

            self.$emit('sendCheckActiveChat', checkActiveChat);
            self.selected = 2;
            self.selectedChat.agent_id = self.user.id;
            self.selectedChat.client_state = 'tomado';
            self.setSelectedChat(self.selectedChat);
            self.addActiveChatTokens(self.roomToken);

            // verifico si estamos en todas las conversas o en chats pendientes
            const chats = self.pendingChats.find(
              (item) => item.room_token == self.roomToken,
            )
              ? self.pendingChats
              : self.db_table;
            // agregando tarjeta a lista de conversas activas
            const chat = chats.find(
              (item) => item.room_token == self.roomToken,
            );
            // Que tarjeta desaparezca de todas mis conversaciones o de pendientes
            self.searchConversationCardAndDelete(self.roomToken);
            // agregando estado tomado
            if (chat) chat.client_state = 'tomado';

            self.activeChatRooms.unshift(chat);
            self.addActiveChatRoom(chat);

            dashboard_api
              .get(`/chat_rooms/${self.roomToken}`)
              .then((response) => {
                if (
                  !response.data.agent_id ||
                  response.data.agent_id == self.user.id ||
                  (self.modal.channel == 'Whatsapp Bot' || self.modal.channel == 'instagram' || self.modal.channel == 'messenger')
                ) {
                  dashboard_api
                    .put(`/chat_rooms/${self.roomToken}`, {
                      client_state: 'tomado',
                      agent_id: self.user.id,
                    })
                    .then((resp) => {
                      // Cambia el agente asignado al tomar la conversacion.
                      const idClient = resp.data.databot_live.lead_id;
                      const agentFind = self.agents.find(
                        (element) => element.user.id === resp.data.agent_id,
                      );
                      const agentConversation = agentFind.user.name;
                      dashboard_api
                        .put(`/relief/upagent/${idClient}`, {
                          agent: agentConversation,
                        })
                        .then((result) => {
                          console.log('Cambio de Agente Asignado', result.data);
                        })
                        .catch((err) => {
                          self.$swal({
                            icon: 'error',
                            title: 'Error al editar el estado del cliente',
                          });
                        });
                      // revisar
                      socket.emit('STATUS', {
                        room: self.roomToken,
                        status: true,
                        user: self.user,
                        botId: self.selected_bot.id,
                        channel: self.modal.channel,
                        sender_id: self.modal.sender_id,
                        options: {
                          botPhone: self.modal.custom
                            ? self.modal.custom.special_bot_phone
                            : null,
                          userPhone: self.modal.phone,
                        },
                      });
                      // Actualizar user id en databotLive
                      dashboard_api
                        .put(
                          `/databot_lives/${self.selectedChat.databot_live_id}`,
                          {
                            databot_live: {
                              user_id: self.user.id,
                            },
                          },
                        )
                        .then((dbReponse) => {
                          const date = moment().format('YYYY-MM');

                          dashboard_api
                            .post(
                              '/dashboards/current_month_data_details_agent',
                              {
                                period: date,
                                bot_id: dbReponse.data.bot_id,
                                user_id: self.user.id,
                              },
                            )
                            .then(() => {})
                            .catch((error) => console.log('error ->', error));
                        })
                        .catch((error) => console.log('error =>', error));
                    })
                    .catch((error) => console.log('error =>', error));
                } else {
                  self.$swal({
                    title: 'Ooops!',
                    text: 'Al parecer este cliente ya fué atendido',
                    icon: 'error',
                    buttons: {
                      confirm: {
                        text: 'Ok',
                        value: true,
                        visible: true,
                        className: '',
                        closeModal: true,
                      },
                    },
                  });
                }
                self.loading = false;
              })
              .catch((error) => console.log('error ->', error));
          }
        });
    },
    // Elimina tarjeta de conversacion y cambia su estado
    async hidePrechat(chat) {
      chat == null ? (chat = this.selectedChat) : chat;
    console.log("desde component");
      this.$swal({
        icon: 'info',
        title: '¿Archivar conversación?',
        showConfirmButton: true,
        confirmButtonText: 'Confirmar',
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await axios.post(environment.socket_url + '/whatsapp/hide-prechat', {
            room: chat.room_token,
            botId: chat.bot_id,
            status: true,
            options: {
              botPhone: this.modal.custom
                ? this.modal.custom.special_bot_phone
                : null,
              userPhone: this.modal.phone,
            },
          });
          this.searchConversationCardAndDelete(chat.room_token);
          this.$swal({
            toast: true,
            position: 'top-end',
            icon: 'success',
            title: 'Conversación archivada',
            showConfirmButton: false,
            timer: 4000,
          });
        }
      });
    },

    addDatabotLiveCard(chatRoom) {
      console.log("Pusheando nuevo chatROOM: ", chatRoom);
      // console.log("Condiciones: ", chatRoom.bot_id === this.selected_bot.id, !this.chatsToShow.some((chat) => chat.id == chatRoom.id), !this.chatRooms.some((chat) => chat.id == chatRoom.id));

      // !this.chatsToShow.some((chat) => chat.id == chatRoom.id) &&
      chatRoom.user_online = true;
      // conectando tarjeta a socket para escuchar de eventos (cuando se elimine)
      if (
        chatRoom.bot_id === this.selected_bot.id &&
        !this.db_table.some((chat) => chat.id == chatRoom.id) &&
        !this.activeChatRooms.some((chat) => chat.id == chatRoom.id) &&
        !this.pendingChats.some((chat) => chat.id == chatRoom.id) // esto para que no existan tarjetas duplicadas
      ) {
        socket.emit('JOIN_ROOM', {
          room: chatRoom.room_token,
          status: false,
        });
        // agregando atributo fecha de ultimo mensaje (si no existe)
        if (!chatRoom.last_message) {
          chatRoom.last_message = {};
          chatRoom.last_message.updated_at = new Date();
        }
        // agregando a la pestaña correspondiente
        if (chatRoom.client_state === 'pendiente') this.pendingChats.unshift(chatRoom);
        if (
          chatRoom.client_state !== 'pendiente' &&
          chatRoom.client_state !== 'tomado'
        ) this.db_table.unshift(chatRoom);
        if (chatRoom.agent_id == this.user.id) this.activeChatRooms.unshift(chatRoom);
        this.chatRooms.push(chatRoom);
      }
    },
    updatePrechatDateAndLastMessage(lastMessage, room) {
      let chatRoom = this.chatsToShow.find(
        (chatToShow) => chatToShow.room_token === room,
      );
      if (chatRoom && chatRoom.last_message) {
        chatRoom.last_message.updated_at = new Date();
        if (lastMessage.from === 'Cliente' && lastMessage.message_type === 'text') {
          chatRoom.last_received_message = lastMessage.text;
        }
      } else if (chatRoom) {
        chatRoom.last_message = {};
        chatRoom.last_message.updated_at = new Date();
      }
    },
    searchConversationCardAndDelete(room, event) {
      this.db_table.forEach((chat_element, index, array) => {
        if (chat_element.room_token == room) {
          if (event === 'disconnect') array[index].client_disconected = true;
          // eliminando tarjeta de la lista de todas las conversas
          array.splice(index, 1);
        }
      });
      this.activeChatRooms.forEach((chat_element, index, array) => {
        if (chat_element.room_token == room) {
          if (event === 'disconnect') array[index].client_disconected = true;
          // eliminando tarjeta de la lista de todas las conversas
          array.splice(index, 1);
        }
      });
      this.pendingChats.forEach((pending, index, array) => {
        if (pending.room_token == room) {
          if (event === 'disconnect') array[index].client_disconected = true;
          // eliminando tarjeta de la lista de pendientes
          array.splice(index, 1);
        }
      });
    },
    filterChats(chats) {
      let fields = Object.keys(this.selectedFilters);
      let hasFieldValues = fields.some(
        (field) => this.selectedFilters[field].length > 0,
      );

      return fields.length > 0 && hasFieldValues
        ? chats.filter((chat) => {
            let satisfy = false;
            let satisfies = []; // todos deben ser true
            for (const field of fields) {
              let filterIndex = this.customFilters.findIndex(
                (el) => el.name === field,
              );
              if (
                (this.selectedFilters[field].includes(
                  chat.databot_live.lead.custom[field],
                ) ||
                  this.selectedFilters[field].some(
                    (el) =>
                      el && el.includes(chat.databot_live.lead.custom[field]),
                  )) &&
                chat.databot_live.lead.custom[field]
              ) {
                satisfies.push(true);
              } else if (
                filterIndex > -1 &&
                this.customFilters[filterIndex].aux_names &&
                this.customFilters[filterIndex].aux_names.length > 0 &&
                this.selectedFilters[field].some((el) =>
                  this.customFilters[filterIndex].aux_names.some((aux_name) => {
                    // console.log("COMPARANDO: ", el, aux_name);
                    return (
                      el &&
                      String(el)
                        .toLowerCase()
                        .includes(
                          String(
                            chat.databot_live.lead.custom[aux_name],
                          ).toLowerCase(),
                        )
                    );
                  }),
                )
              ) {
                satisfies.push(true);
              } else if (
                this.selectedFilters[field].includes(undefined) &&
                !chat.databot_live.lead.custom[field] &&
                this.customFilters[filterIndex].aux_names &&
                this.customFilters[filterIndex].aux_names.every(
                  (el) => !chat.databot_live.lead.custom[el],
                )
              ) {
                satisfies.push(true);
              } else if (
                this.selectedFilters[field].includes(undefined) &&
                !chat.databot_live.lead.custom[field] &&
                !this.customFilters[filterIndex].aux_names
              ) {
                satisfies.push(true);
              } else if (this.selectedFilters[field].length > 0) {
                satisfies.push(false);
              }
            }
            if (satisfies.every((el) => el) && satisfies.length > 0) {
              satisfy = true;
            } else {
              satisfy = false;
            }
            satisfies = [];
            return satisfy;
          })
        : chats;
    },
    pushData(event, increment = false) {
      const self = this;
      let f_tags = 0;
      let dbTableTmp = [];
      let pendingChatsTmp = [];
      let activeChatsTmp = [];
      self.loading = true;

      if (increment) self.perPage += 10;
      if (self.tagsFilter.length > 0) f_tags = self.tagsFilter.map(val => val.id);

      dashboard_api_v2
        .get(
          `/chat_rooms/by_user_paginated/${self.user.id}/${
            self.active_view || '0'
          }/${self.selected_bot.id}/${self.perPage}/${f_tags}`,
        )
        .then(async (chat_rooms) => {
          let chat_rooms_data = chat_rooms.data.data;
          // uniendome a cada room
          // WHAT que hace esto?
          chat_rooms_data.forEach((chatRoom, index) => {
            socket.emit('JOIN_ROOM', {
              room: chatRoom.room_token,
              status: false,
            });
          });
          // store all chatrooms
          self.chatRooms = chat_rooms_data;

          // se verifica y espera si la tarjeta lleva mas de 20 min de conversa (Excepto Whatsapp)
          await Promise.all(
            chat_rooms_data.map((chatRoom) =>
              self.checkIfDeleteConversation(chatRoom),
            ),
          );

          dbTableTmp = chat_rooms_data.filter(
            (chat) =>
              chat.client_state !== 'pendiente' &&
              chat.client_state !== 'tomado' &&
              chat.status === true,
          );
          // Set all chats with disconected user
          dbTableTmp.forEach(el => {
            el.user_online = false;
          });
          self.db_table = dbTableTmp;

          pendingChatsTmp = chat_rooms_data.filter(
            (chat) => chat.client_state === 'pendiente',
          );
          pendingChatsTmp.forEach(el => {
            el.user_online = false;
          });
          self.pendingChats = pendingChatsTmp;
          console.log("chat_rooms_data->", chat_rooms_data);
          activeChatsTmp = chat_rooms_data.filter(
            (chat) =>
              chat.agent_id == self.user.id && chat.client_state === 'tomado',
          );
          activeChatsTmp.forEach(el => {
            el.user_online = false;
          });
          self.activeChats = activeChatsTmp;
          // console.log('allChats->', self.allChats);
          self.setActiveChatRooms(self.activeChats);
          self.loading = false;
        });
    },
    // WHAT esto es necesario? hay otra forma de hacerlo?
    checkIfDeleteConversation(chatRoom) {
      return new Promise(async (resolve, reject) => {
        try {
          if (chatRoom.channel !== 'Whatsapp Bot' && chatRoom.channel !== 'instagram' && chatRoom.channel !== 'messenger') {
            // no queremos que se eliminen tarjetas de Whatsapp
            const diff = moment(new Date()).diff(moment(chatRoom.updated_at));
            // el chat se elimina si lleva activo mas de 20 min y no es una
            if (
              diff > 20 * 60 * 1000 &&
              chatRoom.client_state != 'tomado' &&
              chatRoom.client_state != 'pendiente'
            ) {
              console.log('pasa al if');
              // eliminando de bd
              await dashboard_api_v2.put(`/chat_rooms/by_id/${chatRoom.id}`, {
                chat_room: {
                  status: false,
                },
              });
              // actualizando localmente
              chatRoom.status = false;
            }
          }

          resolve();
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },
    checkIfBotHasCustomFilters() {
      const botWithFilters = this.ownerBots.find((el) =>
        filtersDatabotLive.hasCustomFilters(el.id),
      );
      // el bot tiene filtros personalizados
      if (botWithFilters) {
        this.customFilters = filtersDatabotLive.getFilters(botWithFilters.id);
        this.hasCustomFilters = true;
      }
    },

    async generateChat(chat) {
      const self = this;
      const token = chat.room_token || '';

      let checkActiveChat = await self.activeChatRooms.some(
        (item) => item && item.room_token == token,
      );

      self.$emit('selectedChat', chat);
      self.$emit('sendCheckActiveChat', checkActiveChat);

      return new Promise(async (resolve, reject) => {
        if (!this.chatsToShow.some((el) => el.room_token === token)) return; // esta linea hace que no se genere un chat de una tarjeta inexistente

        // self.$emit('setActiveClient', chat.client_disconected);

        socket.emit('JOIN_ROOM', { room: token, status: false });
        // se guarda token en store
        self.setCurrentRoomToken(token);
      });
    },
    deletePrechat(room) {
      this.searchConversationCardAndDelete(room);
    },
  },
};
</script>

<style lang="scss" scoped>
.chats {
  z-index: 0;
  transition: 0.25s;
  background-color: #fff;
  .menu {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    margin: 0 auto 1rem;

    @media (max-width: 375px) {
      padding: 0;
    }
  }
  .search_menu {
    width: 100%;
    margin-top: 0.75rem;
    display: grid;
    grid-template-columns: 6fr 1fr 1fr;
    column-gap: 1rem;

    input[type='search'] {
      border: 1px solid #cdcdcd;
      border-radius: 0.5rem;
      width: 100%;
      padding: 0.5rem 0.75rem;
      margin-right: 0.75rem;
      margin-bottom: 0;
      background-size: 1.125rem 100%;
    }
  }
  .to_card {
    background-color: transparent;
    border: 1px solid transparent;
    padding: 1rem 0;
    transition: 0.25s;
    border-bottom-color: #f2f2f2;
    cursor: pointer;

    &:hover {
      border-radius: 1rem;
      padding: 1rem 1.25rem;
      background-color: #f2f2f2;
    }
    .card-box {
      display: grid;
      grid-template-columns: 30px 1fr;
      column-gap: 0.75rem;

      .user-img {
        text-transform: capitalize;
      }
    }
  }
  .selected_card {
    background-color: #f2f2f2;
    border-color: #cdcdcd;
    border-radius: 1rem;
    padding: 1rem 1.25rem;
    transition: 0.25s;
  }
  .user-img,
  .user-img-wsp,
  .user-img-instagram {
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 50rem;
    background-color: #2981ef;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 700;
  }
  .user-img-wsp {
    background-color: #9edd63;
    background-image: url('/img/icons/18-i-whatsapp-b.svg');
    background-repeat: no-repeat, repeat;
    background-position: center;
    background-size: 1rem 100%;
  }
  .user-img-instagram {
    background-color: #F5365C;
    background-image: url("/img/icons/31-i-instagram-b.svg");
    background-repeat: no-repeat, repeat;
    background-position: center;
    background-size: 1rem 100%;
  }
  .user-img-messenger {
    background-image: url("/img/logos/2-implementacion/messenger.svg");
    background-repeat: no-repeat, repeat;
    background-position: center;
    background-size: 2rem 100%;
  }
  .etiqueta {
    text-transform: capitalize;
    border-color: #cdcdcd;
    margin: auto;
    cursor: default;

    &:hover {
      color: #767676;
      background-color: #fff;
    }
  }
  .message_notification {
    display: flex;
    align-items: center;
    background-color: #2981ef;
    padding: 0.025rem 0.5rem;
    padding-right: calc(0.5rem - 8px);
    border-radius: 2rem;
    color: #fff;
  }
  .scroll_box {
    height: 750px;
    transition: 0.25s;
    padding: 0 0.5rem 3rem 1rem;
    overflow-y: scroll !important;

    @media (max-width: 1600px) {
      height: 600px;
    }
    @media (max-width: 1279px) {
      height: 100vh;
    }
    @media (max-width: 375px) {
      padding: 0;
      padding-bottom: 25rem;
    }
    &::-webkit-scrollbar-thumb {
      visibility: hidden;

      &:hover, &:active {
        visibility: visible;
      }
    }
  }
  .user-message {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .text-state {
    font-size: 95%;
    background-color: #fff;
  }
}
</style>
