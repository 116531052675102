<template>
  <section>
    <div v-for="(group, groupIndex) in groupedMessages" :key="groupIndex">
      <div
        v-for="(message, messageIndex) in group.grouped"
        :key="messageIndex"
        :class="{
          'd-flex justify-content-end': (group.from ? group.from !== 'Cliente' : message.sender !== 'Cliente') && message.message_type !== 'alert',
        }"
      >
        <div
          :class="{
            'from-client': group.from ? group.from === 'Cliente' : message.sender === 'Cliente',
            'from-agent': group.from ? group.from != 'Cliente' : message.sender != 'Cliente',
            'rich_element': message.message_type == 'audio'
          }"
        >
          <div
            :class="{
              'd-flex align-items-end': true,
              'justify-content-end': group.from ? group.from !== 'Cliente' : message.sender !== 'Cliente'
            }
          ">
            <template v-if="group.from ? group.from === 'Cliente' : message.sender === 'Cliente'">
              <!-- v-if="message.text === group.grouped[group.grouped.length - 1].text" -->
              <span
                :class="{
                  'profile-client desktop-xs mr-3': true,
                  'profile-wsp': modal.channel == 'Whatsapp Bot',
                  'profile-instagram': modal.channel == 'instagram',
                  'profile-messenger': modal.channel == 'messenger'
                }"
              >{{ modal.name && modal.name[0] || "C" }}</span>
              <!-- <span v-else class="space-left"></span> -->
            </template>
            <MessageText
              v-if="!message.message_type || message.message_type == 'text'"
              :message="message"
            />
            <MessageTemplateWhatsApp
              v-if="message.message_type == 'template_wsp' && message.payload && message.payload.template"
              :message="message"
            />
            <div class="custom-p" v-if="message.message_type == 'option'">
              <MessageOption
                :message="message"
              />
            </div>
            <MessageAudio
              v-if="message.message_type == 'audio'"
              :message="message"
            />
            <MessageVideo
              v-if="message.message_type == 'video'"
              :message="message"
            />
            <MessageSticker
              v-if="message.message_type == 'sticker'"
              :message="message"
            />
            <MessageFile
              v-if="message.message_type == 'file'"
              :message="message"
            />
            <MessageImage
              v-if="message.message_type == 'image'"
              :message="message"
            />
            <MessageCarousel
              v-if="message.message_type == 'carousel'"
              :message="message"
            />
            <MessageRating
              v-if="message.message_type == 'rating'"
              :message="message"
            />
            <template v-if="((group.from ? group.from != 'Cliente' : message.sender != 'Cliente') && (message.text && !message.text.includes('Ahora estas conversando con')))">
              <img
                v-if="message.text === group.grouped[group.grouped.length - 1].text"
                class="profile-bot desktop-xs ml-3"
                :src="(group.from ? group.from == 'Watson' : message.sender == 'Watson') ? getImgBot : (user.image ? user.image : 'img/brand/0-default.svg')"
                alt=""
              >
              <span v-else class="space-right"></span>
            </template>
          </div>
        </div>
        <MessageAlert
          v-if="message.message_type == 'alert'"
          :message="message"
          :user="user"
        />
      </div>
    </div>
  </section>
</template>

<script>
import MessageText from '@/components/chat/MessageText.vue';
import MessageAlert from './chat/MessageAlert.vue';
import MessageCarousel from './chat/MessageCarousel.vue';
import MessageFile from '@/components/chat/MessageFile.vue';
import MessageImage from '@/components/chat/MessageImage.vue';
import MessageOption from '@/components/chat/MessageOption.vue';
import MessageAudio from '@/components/chat/MessageAudio.vue';
import MessageVideo from '@/components/chat/MessageVideo.vue';
import MessageSticker from '@/components/chat/MessageSticker.vue';
import MessageTemplateWhatsApp from '@/components/chat/MessageTemplateWhatsApp.vue';
import MessageRating from '@/components/chat/MessageRating.vue';

export default {
  props: {
    messages: {
      type: Array,
      default: () => [],
    },
    bot: {
      type: Object,
      default: () => {},
    },
    user: {
      type: Object,
      default: () => {},
    },
    getImgBot: {
      type: String,
      default: ""
    },
    modal: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      counterbot: 0,
      counterClient: 0
    };
  },
  components: {
    MessageText,
    MessageAlert,
    MessageCarousel,
    MessageFile,
    MessageImage,
    MessageOption,
    MessageAudio,
    MessageSticker,
    MessageRating,
    MessageTemplateWhatsApp,
    MessageVideo
  },
  computed: {
    groupedMessages() {
      return this.messages.reduce((acc, el, index) => {
        let group =
          acc.length > 0 && acc[acc.length - 1].from === el.from
            ? acc[acc.length - 1]
            : null;
        if (group) {
          group.grouped.push(el);
        } else {
          acc.push({
            from: el.from,
            grouped: [el],
            date: el.created_at,
            id: el.id,
          });
        }
        return acc;
      }, []);
    },
  },
};
</script>

<style lang="scss">
.message-owner,
.messageFromYou {
  margin: 0.25rem 0;
  animation-name: fade;
  animation-duration: 0.25s;
}
.messageFromYou {
  margin: 0.25rem 0;
  text-align: right;
}
.from-client {
  max-width: 70%;
  margin-bottom: .5rem;

  .message-inner {
    display: inline-block;
    background-color: #fafafa;
    padding: .75rem 1rem;
    border-radius: 1rem;
    width: fit-content;
    overflow-x: hidden;
    border: 1px solid #cdcdcd;

    pre p {
      margin-bottom: 0;
      position: relative;
      white-space: pre-wrap;
      word-break: break-word;
      color: #181818;

      a {
        color: #2981EF;
        word-break: break-word;
      }
    }
    .text_xs {
      text-align: right;
      color: #181818;
    }
  }
  .bg_img {
    object-fit: cover;
    object-position: center;
    background-color: transparent;
    border-radius: 0 10px 10px 10px;
    border: 1px solid #f2f2f2;
    width: 100%;
    margin-top: 0.5rem;
    height: auto;
    max-width: 200px;
    max-height: 150px;
    overflow: hidden;
  }
}
.from-agent {
  width: 70%;
  text-align: right;
  margin-bottom: .5rem;

  .message-inner {
    width: auto;
    display: inline-block;
    border: 1px solid #bfdbff;
    background-color: #EFF6FF;
    padding: .75rem 1rem;
    border-radius: 1rem;
    margin-left: auto;
    margin-right: 0;
    max-width: 100%;

    pre p {
      position: relative;
      white-space: pre-wrap;
      text-align: left;
      padding: 0;
      background-color: transparent;
      color: #181818;
      margin: auto auto 0;
      word-break: break-word;
    }
    .text_xs {
      color: #181818;
    }
  }
  .bg_img {
    object-fit: cover;
    object-position: center;
    background-color: transparent;
    border-radius: 10px 0 10px 10px;
    border: 1px solid #f2f2f2;
    width: 100%;
    margin-top: 0.5rem;
    height: auto;
    max-width: 200px;
    max-height: 150px;
    overflow: hidden;
  }
}
.profile-client {
  min-width: 30px;
  width: 30px;
  height: 30px;
  display: flex;
  border-radius: 50rem;
  background-color: #b2b2b2;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
}
.profile-wsp {
  background-color: #9edd63;
}
.profile-instagram {
  background-color: #F5365C;
}
.profile-messenger {
  background-color: #2981ef;
}
.profile-bot {
  width: 30px;
  height: 30px;
  border-radius: 50rem;
  object-fit: cover;
  object-position: center;
  border: 1px solid #f2f2f2;
}
.desktop-xs {
  @media (max-width: 480px) {
    display: none;
  }
}

.space-left {
  margin-left: 45px;

  @media (max-width: 480px) {
    margin-right: 0;
  }
}
.space-right {
  margin-right: 45px;

  @media (max-width: 480px) {
    margin-right: 0;
  }
  @media (max-width: 480px) {
    margin-right: 0;
  }
}
.rich_element {
  max-width: 100% !important;
}
.custom-p {
  padding-right: 45px;
}
</style>
